import {NgModule} from '@angular/core';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/login'
  },
  {
    loadChildren: () => import('./authentication-layout/authentication-layout.module')
      .then(m => m.AuthenticationLayoutModule),
    path: ''
  },
  {
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule),
    path: ''
  },
  {
    loadChildren: () => import('./verification-page/verification-page.module').then(m => m.VerificationPageModule),
    path: 'profile'
  },
  {
    component: NotFoundPageComponent,
    path: '**'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
