<div class="container">
  <img [src]="logoPath" alt="Logo of application">
  <div class="left-section">
    <div class="not-found-section">
      <mat-icon>report</mat-icon>
      <h3>{{ 'notFoundPage.title' | translate }}</h3>
    </div>
  </div>

  <div class="right-section" [style.background]="background"></div>
</div>
