import {AppComponent} from './app.component';
import {AppErrorHandler} from './errors/app-error-handler';
import {AppRoutingModule} from './app-routing.module';
import {AuthorizationInterceptor} from './interceptors/authorization.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorDialogComponent} from './errors/error-dialog/error-dialog.component';
import {ErrorHandler, NgModule} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpBackend,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import {LoadingComponent} from './shared/components/loading/loading.component';
import {MaterialModule} from './shared/material.module';
import {NotFoundPageComponent} from './errors/not-found-page/not-found-page.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {environment} from '../environments/environment';
import {provideEnvironmentNgxMask} from 'ngx-mask';

export function HttpLoaderFactory(httpBackend: HttpBackend): TranslateHttpLoader {
  const httpClient = new HttpClient(httpBackend);
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json?cb=' + environment.appVersion);
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    LoadingComponent,
    NotFoundPageComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    MaterialModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpBackend],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory
      }
    })],
  providers: [
    provideEnvironmentNgxMask(),
    {
      provide: ErrorHandler, useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})

export class AppModule {
}
