import {Component} from '@angular/core';
import {LoadingService} from '../../shared/services/loading.service';
import {environment} from '../../../environments/environment';

@Component({
  styleUrls: ['./not-found-page.component.scss'],
  templateUrl: './not-found-page.component.html'
})
export class NotFoundPageComponent {
  public background: string;
  public logoPath: string;

  constructor(private loadingService: LoadingService) {
    this.background = `url("../../../assets/images/${environment.background}") center no-repeat`;
    this.logoPath = `../../../assets/images/${environment.logo}`;
    this.loadingService.hide();
  }
}
