import {Component, OnInit} from '@angular/core';
import {LoadingService} from './shared/services/loading.service';
import {NavigationCancel, NavigationError, NavigationStart, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public loading: boolean;

  constructor(private loadingService: LoadingService,
              private router: Router,
              private titleService: Title,
              private translateService: TranslateService) {
    this.loading = true;

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }

      if (event instanceof NavigationCancel) {
        this.loading = false;
      }

      if (event instanceof NavigationError) {
        this.loading = false;
      }
    });
    this.titleService.setTitle(environment.name);
  }

  public ngOnInit(): void {
    this.loadingService.events.subscribe((event) => {
      setTimeout(() => {
        this.loading = (event === 'show');
      });
    });
  }
}
