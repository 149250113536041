import * as Sentry from '@sentry/angular';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../shared/services/user.service';
import {environment} from '../../environments/environment';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.sentryEnvironment
});

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private loadingService: LoadingService,
              private matDialog: MatDialog,
              private ngZone: NgZone,
              private userService: UserService) {
  }

  async handleError(error: any): Promise<any> {
    if (environment.production) {
      const user = await this.userService.getMe();
      if (user) {
        Sentry.setUser({
          email: user.email
        });
      }
      Sentry.captureException(error);
    } else {
      console.log(error);
    }
    this.loadingService.hide();
    this.ngZone.run(() => {
      this.matDialog.open(ErrorDialogComponent);
    });
  }
}
